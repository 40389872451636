<template>
  <div>
    <section>
      <div class="funnel-wrapper">
        <div class="step">
          <h2>{{ $t('login.login') }}</h2>
          <div class="input-data">
            <div id="recaptcha-container"></div><br>

            <Spinner v-if="verifyingNumberInProgress || showSpinner || !getCountryCode()" />

            <div v-else>
              <div v-if="!showCodeField">
                <label for="phone-17_phone_number">{{ $t('funnel.phone_label') }}</label>
                <VuePhoneNumberInput v-model="phone" id="phone" size="lg" :border-radius="borderRadius" :default-country-code="getCountryCode()" @update="updatePhoneNumber" :translations="translatePhoneInput" :only-countries="getOnlyCountries()" />
              </div>
              <div v-else>
                <label for="otp">{{ $t('funnel.otp_label') }}</label>
                <input type="text" id="otp" v-model="firebaseOTP" placeholder="123456" :class="{'error' : otpError }">
              </div>
              <p class="smaller error" v-if="phoneError">{{ $t('funnel.error_sending_confirmation_code') }}</p>
              <p class="smaller error" v-if="otpError">{{ $t('funnel.wrong_code_entered') }}</p>
              <br>
              <Button :label="$t('login.login')" modifier="funnel-button" @click.native="login()" v-if="!showCodeField"/>
              <div v-else>
                <Button :label="$t('funnel.verify_otp')" modifier="funnel-button" @click.native="verifyOtp()" />
                <p class="back" @click="login()">{{ $t('funnel.resend_code') }}</p>
              </div>
            </div>
        
            <p class="copy-text" v-html="$t('funnel.gdpr_text')"></p>
          </div>
        </div>
       
      </div>
    </section>

    <Modal v-if="confirmLegal" @close="confirmLegal = false" @confirm="acceptUpdatedTerms()">
      <template v-slot:header>
        <p class="modal-copy-text" v-html="$t('funnel.we_updated_legal')"></p>
      </template>
    </Modal>

  </div>
</template>

<script>
import Spinner from '@/components/Spinner'
import Modal from '@/components/ModalComponent'
import Button from '@/components/Button'
import firebase from 'firebase/app'
import 'firebase/auth'
import i18n from '../i18n'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Login',
  components: {
    Button,
    VuePhoneNumberInput,
    Modal,
    Spinner
  },
  data() {
    return {
      phone: '',
      formattedPhoneNumber: '',
      appVerifier : '',
      firebaseOTP : '',
      otpError: false,
      phoneError: false,
      disabledButton: true,
      verifyingNumberInProgress: false,
      showCodeField: false,
      borderRadius: 0,
      translatePhoneInput: null,
      confirmLegal: false,
      showSpinner: false
    }
  },
  computed: {
    newLocale:() => {
      return i18n.locale
    }
  },
  watch: {
    newLocale: function() {
      this.changeTranslation(this.newLocale)
    }
  },
  created() {
    this.initFirebase()
  },
  mounted() {
    if(this.appVerifier === '') {
      this.initReCaptcha()
    }
    window.addEventListener('keyup',(e) => {
      if(e.keyCode === 13) {
        if(this.showCodeField) {
          this.verifyOtp()
        } else {
          this.login()
        }
      }
    })
  },
  destroyed() {
    window.removeEventListener('keyup',(e) => {
      if(e.keyCode === 13) {
        if(this.showCodeField) {
          this.verifyOtp()
        } else {
          this.login()
        }
      }
    })
  },
  methods: {
    ...mapActions([
      'acceptTerms',
      'getPP',
      'getTerms',
      'loginSignupUser',
      'getReceipt',
      'fetchUUID'
    ]),
    ...mapGetters([
      'getCountryCode',
      'getOnlyCountries'
    ]),
    ...mapMutations([
      'setUserPhoneNumber',
      'setFirebaseUID'
    ]),
    changeTranslation(locale) {
      switch(locale) {
        case 'en': 
          this.translatePhoneInput = null
          break
        case 'de': 
          this.translatePhoneInput = {
            countrySelectorLabel: 'Landesvorwahl',
            countrySelectorError: 'Wähle ein Land',
            phoneNumberLabel: 'Telefonnummer',
            example: 'Beispiel:'
          }
          break
        case 'es': 
          this.translatePhoneInput = {
            countrySelectorLabel: 'Código de país',
            countrySelectorError: 'Elige un pais',
            phoneNumberLabel: 'Número de teléfon',
            example: 'Ejemplo:'
          }
          break
        case 'ja': 
          this.translatePhoneInput = {
            countrySelectorLabel: '国コード',
            countrySelectorError: '国を選択します',
            phoneNumberLabel: '電話番号',
            example: '例:'
          }
          break
        case 'zh-cn': 
          this.translatePhoneInput = {
            countrySelectorLabel: '国家代码',
            countrySelectorError: '选择一个国家',
            phoneNumberLabel: '电话号码',
            example: '例子:'
          }
          break
        default: 
          this.translatePhoneInput = null
      }
    },
    updatePhoneNumber(value) {
      this.formattedPhoneNumber = value.formattedNumber
      this.setUserPhoneNumber(this.formattedPhoneNumber)
    },
    initFirebase(){
      if (!firebase.apps.length) {
        firebase.initializeApp({
          apiKey: "AIzaSyApnqBy3JhfbpMGo-7DJcwwswDsMh1tCnA",
          authDomain: window.location.hostname,
          databaseURL: "https://tapeacall-145617.firebaseio.com/",
          projectId: "tapeacall-145617",
          storageBucket: "user-upload-holding",
          messagingSenderId: "98361385677",
          appId: "1:98361385677:web:75e12f1a58966e5c7d3f84"
        })

        //set firebase auth lang based on users selected language
        firebase.auth().languageCode = i18n.locale
      }
    },
    verifyOtp(){
      if(this.firebaseOTP.trim().length === 0) {
        this.otpError = true
        return
      }

      //show spinner
      this.showSpinner = true
      window.confirmationResult.confirm(this.firebaseOTP).then((result) => {
        // User signed in successfully. Get the IDToken and hash PP and ToS and send it to the backend
        let user = result.user
        this.setFirebaseUID(user.uid)
        user.getIdToken().then((idToken) => {
          this.loginSignupUserMethod(idToken)
        }).catch((error)=> {
          console.error('Error getting ID Token', error)
        })
      }).catch((error) => {
        // User couldn't sign in (bad verification code?) but now we know the number is not valid
        console.error('Invalid phone number', error)
        this.otpError = true
        this.showSpinner = false
      })
    },
    loginSignupUserMethod(idToken) {
      this.loginSignupUser(idToken).then((newUser) => {
        if(newUser) {
          this.sendSignupEvent()
        }
        this.redirectUser()
      }).catch((statusCode) => {
        //error happened while signing user up, check if statusCode is 451 and update ToS and PP hashes
        console.error('Error signing up user', statusCode)
        if(statusCode === 451) {
          this.getPP()
          this.getTerms()
          this.confirmLegal = true
          this.showSpinner = false
        } else {
          this.otpError = true
          this.showSpinner = false
        }
      })
    },
    sendSignupEvent() {
      this.fetchUUID().then((uuid) => {
        //send GTM event user signed up successfully if he is a new user
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'gtag_event',
          'event_action': 'signup',
          'event_label': 'user_signup',
          'UserID': uuid
        })
      }).catch((error) => {
        console.error('Error getting UUID', error)
      })
    },
    redirectUser() {
      this.$nextTick(() => {
        this.getReceipt().then((response) => {
          if(response) {
            this.showSpinner = false
            this.$router.push('dashboard')
          } else {
            this.$router.push('/')
          }
        }).catch((statusCode) => {
          //statusCode is 451 update ToS and PP hashes
          console.error('Error signing up user', statusCode)
          if(statusCode === 451) {
            this.getPP()
            this.getTerms()
            this.confirmLegal = true
            this.showSpinner = false
          } else {
            this.$router.push('/')
          }
        })
      })
    },
    initReCaptcha(){
      setTimeout(()=>{
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          'size': 'invisible',
          'callback':() => {},
          'expired-callback':() => {}
        })
        this.appVerifier = window.recaptchaVerifier
      },1000)
    },
    acceptUpdatedTerms() {
      this.acceptTerms().then(() => {
        this.confirmLegal = false
      }).catch((error) => {
        console.error('Terms not accepted', error)
      })
    },
    login() {
      //prevent multiple verifying
      if(this.verifyingNumberInProgress) return

      this.phoneError = false
      this.otpError   = false

      if(!this.formattedPhoneNumber || this.formattedPhoneNumber === '') {
        this.phoneError = true
        return
      }

      this.verifyingNumberInProgress = true

      firebase.auth().signInWithPhoneNumber(this.formattedPhoneNumber, this.appVerifier)
        .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message
        // Usually we sign the user in with confirmationResult.confirm(code) but we only need to confirm the number for now
        window.confirmationResult = confirmationResult
        this.showCodeField = true
        this.verifyingNumberInProgress = false
      }).catch((error) => {
        console.error('SMS not sent', error)
        this.showCodeField = false
        this.phoneError = true
        this.verifyingNumberInProgress = false
      })
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/partials/_funnel";
</style>
